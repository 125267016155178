import { useState, useEffect } from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import {  Spinner } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import config from '../../config.js';
import UsernameModal from "../../layouts/UsernameModal.js"; // Import the modal component

const Likes = ({ quillId }) => {
  const [likes, setLikes] = useState([]);
  const [liked, setLiked] = useState(false);
  const [userId, setUserId] = useState(null); // Store userId
  const [loading, setLoading] = useState(true); // New state to track loading
  const [showUsernameModal, setShowUsernameModal] = useState(false);

  const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated, loginWithRedirect } = useAuth0();

  // Fetch user ID on mount
  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const claims = await getIdTokenClaims();
        setUserId(claims?.username); // Store the username (or use `sub` if that's the actual ID)
      } catch (error) {
        console.error("Error fetching user ID:", error);
      }
    };

    if (isAuthenticated) {
      fetchUserId();
    }
  }, [isAuthenticated, getIdTokenClaims]);

  useEffect(() => {
    if (quillId) {
      fetchLikes();
    }
  }, [quillId]); // Re-run when quillId changes

  const fetchLikes = async () => {
    // setLoading(true); // Start loading
    try {
      if (!quillId) return; // Ensure quillId is defined before making the request

      // Attempt to get the access token silently if authenticated
      const token = isAuthenticated ? await getAccessTokenSilently() : null;
      const apiUrl = `${config.apiUrl}/${config.apiStage}/likes?quill_id=${quillId}`;

      const headers = {
        "Content-Type": "application/json",
      };

      // Only include Authorization header if the token is available
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: headers,
      });

      const data = await response.json();
      setLikes(data || []); // Set the array of usernames (default to empty if undefined)

      if (isAuthenticated && userId) {
        setLiked(data?.some(username => username.toLowerCase() === userId.toLowerCase()));
      }
    } catch (error) {
      console.error("Error fetching likes:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleLike = async () => {
    if (!isAuthenticated) {
      const confirmLogin = window.confirm("Login to react! Do you want to log in?");
      if (confirmLogin) {
        const currentPath = window.location.pathname; // Get the current page path          
        loginWithRedirect({
          redirectUri: `${window.location.origin}/callback?redirectTo=${encodeURIComponent(currentPath)}`,
        });
      }
      return;
    }

    if (isAuthenticated && !userId) {
      const confirmSetUsername = window.confirm("You must set a username to react! Would you like to set one now?");
      if (confirmSetUsername) {
        setShowUsernameModal(true);
      }
      return;
    }

    try {
      if (!quillId) return;

      const token = await getAccessTokenSilently();
      const apiUrl = `${config.apiUrl}/${config.apiStage}/like`;

      const method = liked ? "DELETE" : "POST"; // Toggle between POST and DELETE
      const body = JSON.stringify({ quill_id: quillId });

      await fetch(apiUrl, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: body,
      });

      setLiked(!liked); // Toggle liked state after request
      fetchLikes(); // Refresh likes
    } catch (error) {
      console.error("Error liking/unliking the post:", error);
    }
  };

  const handleUsernameUpdate = async () => {
    console.log("Username updated, refreshing token...");
    const currentPath = window.location.pathname;         
    await loginWithRedirect({
      redirectUri: `${window.location.origin}/callback?redirectTo=${encodeURIComponent(currentPath)}`,
    });
  };

  if (loading) {
    // return <div><Spinner color="info" /> </div>; // Show loading message
    return <div></div>
}

  return (
    <>
      <button onClick={handleLike} className="flex items-center">
        {liked ? <FaHeart size={24} color="red" className="mr-2"/> : <FaRegHeart size={24} color="gray" className="mr-2"/>}
        <span>{likes.length}</span>
      </button>

      {showUsernameModal && (
        <UsernameModal 
          isOpen={showUsernameModal} 
          onClose={() => setShowUsernameModal(false)} 
          onSuccess={handleUsernameUpdate} 
          onUsernameSet={(newUsername) => {
            setUserId(newUsername);
            setShowUsernameModal(false);
            handleLike();
          }} 
        />
      )}
    </>
  );
};

export default Likes;
